<template>
  <div class="main">

    <img width="295" src="@/assets/images/linkFail.png" />
    <span style="margin-top: 30px;font-size: 14px;font-weight: 500;color: #090932;">系统维护中，感谢您的支持</span>
    <div style="height: 200px;"></div>
  </div>
</template>

<script>
export default {
  name: "linkFail",
  methods: {
  }
};
</script>
<style scoped>
.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0.1rem;
  background: rgb(255, 255, 255);

}
</style>
